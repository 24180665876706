import React, { useState } from "react";
import "./form.css";
import validator from "validator";
import axios from "axios";
import { isValidPhoneNumber } from "libphonenumber-js";

export default function FormChametz({ setMsgmodal }) {
  const [message, setMessage] = useState({
    name: "",
    address: "",
    phone: "",
    mail: "",
    message: "",
    ok: "",
  });
  const [errMessageEmail, setErrorMessageEmail] = useState("");
  const [errMessagePhone, setErrorMessagePhone] = useState("");
  const [errMessageName, setErrorMessageName] = useState("");
  const [errAddress, setErrorAddress] = useState("");
  const [errOK, setErrOK] = useState("");

  const send = () => {
    // axios.post("/api/chametz/", message);
    setMsgmodal(true);
    setMessage({
      name: "",
      infoCallBack: "",
      subject: "",
      callBack: "",
      message: "",
    });
    setTimeout(function () {
      setMsgmodal(false);
    }, 10000);
  };

  const ok = () => {
    message.name.length > 6 &&
    message.address.length > 6 &&
    message.ok.length > 0 &&
    validator.isEmail(message.mail) &&
    isValidPhoneNumber(message.phone, "IL")
      ? send()
      : message.name.length < 6
      ? setErrorMessageName("נא להכניס שם מלא")
      : setErrorMessageName("");

    message.address.length < 6
      ? setErrorAddress("נא להכניס כתובת מדוייקת")
      : setErrorAddress("");

    message.ok.length < 1
      ? setErrOK("נא לאשר את פרטי והסכם המכירה")
      : setErrOK("");

    !validator.isEmail(message.mail)
      ? setErrorMessageEmail("נא להכניס כתובת מייל תקינה")
      : setErrorMessageEmail("");

    message.phone.length < 9 || !isValidPhoneNumber(message.phone, "IL")
      ? setErrorMessagePhone("נא להכניס מספר טלפון תקין")
      : setErrorMessagePhone("");
  };
  // };

  const onchange = (e) => {
    setMessage({
      ...message,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="form_chametz">
      <div className="divinput_err">
        <div className="divInput">
          <label className="labelInput">שם</label>
          <input
            className="input"
            type="text"
            name="name"
            onChange={(e) => {
              onchange(e);
              setErrorMessageName("");
            }}
          />
        </div>
        <div className="divErr">{errMessageName}</div>{" "}
      </div>

      <div className="divinput_err">
        <div className="divInput">
          <label className="labelInput">כתובת מלאה</label>
          <input
            className="input"
            type="text"
            name="address"
            onChange={(e) => {
              onchange(e);
              setErrorAddress("");
            }}
          />
        </div>
        <div className="divErr">{errAddress}</div>{" "}
      </div>

      <div className="divinput_err">
        <div className="divInput">
          <label className="labelInput">מייל</label>
          <input
            className="input"
            type="text"
            name="mail"
            onChange={(e) => {
              onchange(e);
              setErrorMessageEmail("");
            }}
          />
        </div>
        <div className="divErr">{errMessageEmail}</div>{" "}
      </div>

      <div className="divinput_err">
        <div className="divInput">
          <label className="labelInput">טלפון</label>
          <input
            className="input"
            type="text"
            name="phone"
            onChange={(e) => {
              onchange(e);
              setErrorMessagePhone("");
            }}
          />
        </div>

        <div className="divErr">{errMessagePhone}</div>
      </div>

      <div className="div-textarea-Details">
        <label className="labelInput"> הערות / פירוט</label>
        <div
          onInput={(e) => {
            setMessage({
              ...message,
              message: e.currentTarget.textContent,
            });
          }}
          className="textarea"
          contentEditable
        />
      </div>

      <div className="divErr"></div>

      <div className="div-callBack">
        <input
          type="checkbox"
          name="ok"
          onChange={(e) => {
            onchange(e);
            setErrOK("");
          }}
          // checked={labelCheckbox === message.callBack ? true : false}
        />{" "}
        <label> מאשר פרטי המכירה</label>
        <br />
        <div className="divErr">{errOK}</div>
      </div>
      <button onClick={ok}>שלח</button>
    </div>
  );
}
