import "./iconBottom.css";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Message, WhatsApp } from "@mui/icons-material";

function Messages() {
  return (
    <div className="iconBottom">
      <div
        className="div_icon"
        onClick={() => {
          window.open(process.env.REACT_APP_WHATSAPP);
        }}
      >
        <WhatsApp />
      </div>{" "}
      <div
        className="div_icon"
        onClick={() => {
          window.open(process.env.REACT_APP_SMS);
        }}
      >
        <Message style={{ fontSize: "6vh", color: "blue" }} />
      </div>
    </div>
  );
}

export default Messages;
