import React from "react";
import "./frame.css";

function Frame() {
  return (
    <div className="frame">
      <div className="frame_background">
        <div className="mid-border">
          <div className="inner-border">
            <img
              className="corner-decoration corner-left-top"
              src={"https://i.ibb.co/4mKvK3N/corner-decoration.jpg"}
              alt=""
            ></img>
            <img
              className="corner-decoration corner-right-top"
              src={"https://i.ibb.co/4mKvK3N/corner-decoration.jpg"}
              alt=""
            ></img>
            <img
              className="corner-decoration corner-right-bottom"
              src="https://i.ibb.co/4mKvK3N/corner-decoration.jpg"
              alt=""
            ></img>
            <img
              className="corner-decoration corner-left-bottom"
              src="https://i.ibb.co/4mKvK3N/corner-decoration.jpg"
              alt=""
            ></img>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
export default Frame;
