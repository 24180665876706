import React, { useState } from "react";
import "./addNames.css";
import axios from "axios";
import Frame from "../header/frame/frame";

export default function AddNames() {
  const [errMessage, setErrorMessage] = useState("");
  const [okSend, setOkSend] = useState(false);
  const [addNames, setAddNames] = useState({
    tytle: "",
    name: "",
    Gender: "",
    parentName: "",
  });
  const send = () => {
    if (
      addNames.tytle.length < 1 ||
      addNames.name.length < 1 ||
      addNames.Gender.length < 1 ||
      addNames.parentName.length < 1
    ) {
      setErrorMessage("יש למלא את כל השדות");
    } else {
      axios.post("/api/addNames/", addNames).then((res) => {});
      setErrorMessage("");
      setOkSend(true);
    }
  };

  const onchange = (e) => {
    setAddNames({
      ...addNames,
      [e.target.name]: e.target.value,
    });
    setErrorMessage("");
  };
  return (
    <div className="addNames">
      מעוניינים לצרף שם לברכה? הצלחה? בריאות? או לעילוי נשמת? מלאו את הפרטים
      וזכויות בית ההוראה יעמוד לזכותו
      {okSend ? (
        <div className="formAddNames">
          {`${addNames.tytle} ${addNames.name} ${addNames.Gender} ${addNames.parentName}`}
          <br />
          התווסף לרשימת ההקדשות.
          <br />
          <br />
          <button onClick={() => setOkSend(false)}>
            רוצה לצרף שם נוסף?
          </button>{" "}
        </div>
      ) : (
        <div className="formAddNames">
          <div className="div_input">
            <select
              required
              style={{ width: "15%" }}
              className="input"
              name="tytle"
              onChange={onchange}
            >
              <option value={""} disabled selected hidden>
                הקדשה
              </option>
              <option value={"לרפואת"}>לרפואה</option>
              <option value={"להצלחת"}>להצלחה</option>
              <option value={"זיווג הגון"}>זיווג הגון</option>
              <option value={"לידה קלה"}>ללידה קלה</option>
              <option value={"לעילוי נשמת"}>לעילוי נשמת</option>
            </select>
            <input
              style={{ width: "25%" }}
              className="input"
              type="text"
              name="name"
              placeholder="שם"
              onChange={onchange}
            />
            <select
              required
              style={{ width: "15%" }}
              className="input"
              name="Gender"
              onChange={onchange}
            >
              <option value={""} disabled selected hidden>
                בן / בת
              </option>
              <option value={"בן"}>בן</option>
              <option value={"בת"}>בת</option>
            </select>
            <input
              style={{ width: "25%" }}
              className="input"
              type="text"
              name="parentName"
              placeholder="שם ההורה"
              onChange={onchange}
            />
          </div>
          <div className="divErr">{errMessage}</div>
          <button onClick={send}>שלח</button>
        </div>
      )}
      <div className="frame">
        <Frame />
        <div className="paybox">
          מעוניינים לתרום להחזקת בית ההוראה ולהיות שותפים בהחזקת התורה בעם
          ישראל?{" "}
          <a
            href="https://payboxapp.page.link/iREx5cAipu8uhnPY9"
            // target="_blank"
            rel="noreferrer"
          >
            לחצו כאן
          </a>
        </div>
      </div>
    </div>
  );
}
