import React, { useState } from "react";
import "./form.css";
import validator from "validator";
import axios from "axios";
import { isValidPhoneNumber } from "libphonenumber-js";

export default function Form({ setMsgmodal }) {
  const [message, setMessage] = useState({
    name: "",
    infoCallBack: "",
    subject: "",
    callBack: "",
    message: "",
  });
  const [errMessageEmail, setErrorMessageEmail] = useState("");
  const [errMessagePhone, setErrorMessagePhone] = useState("");
  const [errMessageSubject, setErrorMessageSubject] = useState("");
  const [errMessage, setErrorMessage] = useState("");
  const [errMessageCallBack, setErrorMessageCallBack] = useState("");
  const labelCheckbox = ["מייל", "הודעת SMS", "ווצאפ"];

  const send = () => {
    if (message.message.length < 6 && message.subject.length < 1) {
      setErrorMessage("מה תרצה לברר?");
      setErrorMessageSubject("נא לבחור את הנושא שאתה מעוניין לברר");
      setErrorMessageEmail("");
      setErrorMessageCallBack("");
    } else if (message.message.length < 6) {
      setErrorMessage("מה תרצה לברר?");
      setErrorMessageSubject("");
      setErrorMessageEmail("");
      setErrorMessageCallBack("");
    } else if (message.subject.length < 1) {
      setErrorMessage("");
      setErrorMessageSubject("נא לבחור את הנושא שאתה מעוניין לברר");
      setErrorMessageEmail("");
      setErrorMessageCallBack("");
    } else if (message.callBack.length < 1) {
      setErrorMessage("");
      setErrorMessageSubject("");
      setErrorMessageCallBack("נא לבחור באיזו דרך תרצה שנחזור אליך עם תשובה");
      setErrorMessageEmail("");
    } else if (message.callBack === "מייל" && message.infoCallBack.length < 1) {
      setErrorMessage("");
      setErrorMessageSubject("");
      setErrorMessageCallBack("");
      setErrorMessageEmail("נא למלא כתובת אימייל");
    } else if (
      message.callBack === "ווצאפ" &&
      message.infoCallBack.length < 1
    ) {
      setErrorMessage("");
      setErrorMessageSubject("");
      setErrorMessageCallBack("");
      setErrorMessageEmail("נא למלא מס' וואצאפ");
    } else if (
      message.callBack === "הודעת SMS" &&
      message.infoCallBack.length < 1
    ) {
      setErrorMessage("");
      setErrorMessageSubject("");
      setErrorMessageCallBack("");
      setErrorMessageEmail("נא למלא מספר טלפון");
    } else if (
      message.callBack === "מייל" &&
      !validator.isEmail(message.infoCallBack)
    ) {
      setErrorMessage("");
      setErrorMessageSubject("");
      setErrorMessageCallBack("");
      setErrorMessageEmail("כתובת מייל לא תקינה");
    } else if (
      (message.callBack !== "מייל" && message.infoCallBack.length < 9) ||
      (message.callBack !== "מייל" &&
        !isValidPhoneNumber(message.infoCallBack, "IL"))
    ) {
      setErrorMessage("");
      setErrorMessageSubject("");
      setErrorMessageCallBack("");
      setErrorMessageEmail("מס' הטלפון אינו תקין");
    } else {
      setErrorMessage("");
      setErrorMessageSubject("");
      setErrorMessageCallBack("");
      setErrorMessageEmail("");
      axios.post("/api/message/", message);
      setMsgmodal(true);
      setMessage({
        name: "",
        infoCallBack: "",
        subject: "",
        callBack: "",
        message: "",
      });
      setTimeout(function () {
        setMsgmodal(false);
      }, 10000);
    }
  };

  const onchange = (e) => {
    setMessage({
      ...message,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="form">
      <div className="divInput">
        <label className="labelInput">שם</label>
        <input
          className="input"
          type="text"
          name="name"
          onChange={onchange}
          placeholder="לא חובה"
        />
      </div>
      <div className="divErr"></div>
      <div className="divInput">
        <label className="labelInput">נושא הפניה</label>
        <select
          className="input"
          id="width-select"
          name="subject"
          onChange={onchange}
        >
          <option value={""} disabled selected></option>
          <option value={"טהרה"}>שאלה הלכתית בנושא הטהרה</option>
          <option value={"כשרות"}>שאלה בנושא כשרות המטבח</option>
          <option value={"שבת"}>שאלה בהלכות שבת</option>
          <option value={"שבת"}>שאלה בהלכות אבלות</option>
          <option value={"שבת"}>שאלה בהלכות אישות</option>
          <option value={"שבת"}>שאלה בהלכות תפילה וברכות</option>
          <option value={"ברכה"}>בקשת ברכה</option>
          <option value={"אחר"}>אחר</option>
        </select>
      </div>
      <div className="divErr">{errMessageSubject}</div>
      <div className="div-textarea-Details">
        <label className="labelInput">שאלתך</label>
        <div
          onInput={(e) => {
            setMessage({
              ...message,
              message: e.currentTarget.textContent,
            });
          }}
          className="textarea"
          contentEditable
        />
      </div>
      <div className="divErr">{errMessage}</div>
      איך תרצו שנחזור אליכם עם תשובה?
      <div className="div-callBack">
        {labelCheckbox.map((labelCheckbox) => (
          <div key={labelCheckbox}>
            <input
              type="checkbox"
              value={labelCheckbox === message.callBack ? "" : labelCheckbox}
              name="callBack"
              onChange={(e) => {
                onchange(e);
                setErrorMessageEmail("");
                setErrorMessageCallBack("");
              }}
              checked={labelCheckbox === message.callBack ? true : false}
            />
            <label>{labelCheckbox}</label>
          </div>
        ))}
      </div>
      <div className="divErr">{errMessageCallBack}</div>
      {message.callBack.length > 1 && (
        <div>
          <div className="divInput">
            <label className="labelInput">
              {message.callBack === "מייל"
                ? "כתובת המייל"
                : message.callBack === "הודעת SMS"
                ? "מס' הטלפון"
                : "מס' הווצאפ"}
            </label>
            <input
              className="input"
              type="text"
              name="infoCallBack"
              onChange={onchange}
            />
          </div>
          <div className="divErr">
            {errMessageEmail}
            {errMessagePhone}
          </div>
        </div>
      )}
      {/* <button onClick={() => setMsgmodal(true)}>שלח</button> */}
      <button onClick={send}>שלח</button>
    </div>
  );
}
