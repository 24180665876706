import React from "react";
import Hebcal from "hebcal";
import "./header.css";
import Logo from "./logo.png";
import Slider from "./slider/slider";
import Frame from "./frame/frame";
import { Link } from "react-router-dom";

function Header() {
  var days = ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"];
  var day = days[new Date().getDay()];

  return (
    <div className="header">
      <div className="div-logo">
        <Link to="/">
          <img src={Logo} alt="" className="logo" />
        </Link>
      </div>
      <div className="zicaron">
        {`יום ${day}, ${new Hebcal.HDate().toString("h")}`}
        <br />
        זכויות לימוד התורה היום מוקדשות:
        <div className="all_zicaron">
          <div className="zicaron_yomi">
            <Frame />
            <div className="zicaron_text">
              לעילוי נשמת <br />
              אליהו ניסים בן חנה זצ"ל
            </div>
          </div>
          <div className="zicaron_slider">
            <Frame />
            <div className="zicaron_text">
              <Slider />
            </div>
          </div>
        </div>
        מעוניינים לצרף שמות לברכה? הצלחה? בריאות? או לעילוי נשמת?{" "}
        <a
          href="/add-names"
          // href="https://payboxapp.page.link/iREx5cAipu8uhnPY9"
          // target="_blank"
          rel="noreferrer"
        >
          לחצו כאן
        </a>
      </div>
    </div>
  );
}
export default Header;
