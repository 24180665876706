import React, { useState } from "react";
import "./chametz.css";
import FormChametz from "./form/form";
import CancelIcon from "@mui/icons-material/Cancel";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Link } from "react-router-dom";

function Chametz() {
  const [msgmodal, setMsgmodal] = useState(false);

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setMsgmodal(false);
    }

    return (
      <div className="timer">
        <CancelIcon
          style={{ fontSize: 28 }}
          onClick={() => setMsgmodal(false)}
        />
      </div>
    );
  };

  return msgmodal === false ? (
    <div className="message">
      <Link to="/"> שאל את הרב </Link>

      <div className="message-header">טופס מכירת חמץ </div>
      <FormChametz setMsgmodal={setMsgmodal} />
    </div>
  ) : (
    <div className="msgmodal">
      <div className="timer-wrapper">
        <CountdownCircleTimer
          isPlaying
          duration={10}
          colors={[["#8a8a8a"]]}
          onComplete={() => [true, 1000]}
          size={32}
          strokeWidth={6}
        >
          {renderTime}
        </CountdownCircleTimer>
      </div>
      פנייתכם נשלחה בהצלחה.
      <br />
      <br /> בדקות הקרובות תקבלו מענה לפנייתכם ע"י אחד הרבנים.
      <br />
      <br /> אנו שמחים שפניתם אלינו ומקווים כי תפיקו תועלת מהמענה שתקבלו.
      <br />
    </div>
  );
}

export default Chametz;
