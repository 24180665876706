import { WhatsApp } from "@mui/icons-material";
import "./whatsapp.css";

export default function SendWhatsApp() {
  return (
    <div className="whatsapp">
      ניתן להפנות שאלות בהלכה לרבנים גם דרך הווצאפ
      <br />
      <br />
      <a href={process.env.REACT_APP_WHATSAPP} target="_blank" rel="noreferrer">
        {" "}
        למעבר לווצאפ
      </a>
      <br />
      <br />
      <WhatsApp
        onClick={() => {
          window.open(process.env.REACT_APP_WHATSAPP);
        }}
      />
    </div>
  );
}
