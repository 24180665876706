import React, { useEffect, useState } from "react";
import "./slider.css";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

function Slider() {
  const [listNames, setListNames] = useState([]);

  useEffect(() => {
    axios.post("/api/listNames/").then((res) => {
      setListNames(res.data);
    });
  }, []);

  return (
    <div className="keeider" dir="ltr">
      <Carousel
        indicators={false}
        controls={false}
        slide={false}
        interval={3000}
      >
        {listNames.map((name) => (
          <Carousel.Item>
            <div dir="rtl">
              {name.tytle} <br />
              {name.name} {name.Gender} {name.parentName}{" "}
              {name.tytle === "לעילוי נשמת" && name.Gender === "בן"
                ? `ז"ל`
                : name.tytle === "לעילוי נשמת" && name.Gender === "בת"
                ? `ע"ה`
                : ""}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>{" "}
    </div>
  );
}
export default Slider;
