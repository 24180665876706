import React, { useState } from "react";
import "./message.css";
import Form from "./form/form";
import WhatsApp from "./whatsapp/whatsapp";
import CancelIcon from "@mui/icons-material/Cancel";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Link } from "react-router-dom";

function Messages() {
  const [msgmodal, setMsgmodal] = useState(false);

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setMsgmodal(false);
    }

    return (
      <div className="timer">
        <CancelIcon
          style={{ fontSize: 28 }}
          onClick={() => setMsgmodal(false)}
        />
      </div>
    );
  };

  return msgmodal === false ? (
    <div className="message">
      {/* <Link to="./chametz">מכירת חמץ </Link> */}
      <div className="message-header">
        {/* מכון ההלכה זכרון אליהו שם לו למטרה לעזור לכל הפונים אליו במענה הלכתי
          תורני.
          <br /> */}
        {/* מעל עשור שרבני בית ההוראה משיבים לפניות השואלים במתינות ונעימות מתוך
          רצון להנגיש את התורה וההלכה לכל עם ישראל. <br /> */}
        רבני בית ההוראה כאן בשבילכם לתת מענה הלכתי תורני מהיר באופן ישיר לפונה.{" "}
        <br />
        ניתן לפנות בצורה אנונימית והמענה ינתן במייל או בהודעה חוזרת.
      </div>
      <div className="div-form">
        <Form setMsgmodal={setMsgmodal} />
        <WhatsApp />
      </div>
    </div>
  ) : (
    <div className="msgmodal">
      <div className="timer-wrapper">
        <CountdownCircleTimer
          isPlaying
          duration={10}
          colors={[["#8a8a8a"]]}
          onComplete={() => [true, 1000]}
          size={32}
          strokeWidth={6}
        >
          {renderTime}
        </CountdownCircleTimer>
      </div>
      פנייתכם נשלחה בהצלחה.
      <br />
      <br /> בדקות הקרובות תקבלו מענה לפנייתכם ע"י אחד הרבנים.
      <br />
      <br /> אנו שמחים שפניתם אלינו ומקווים כי תפיקו תועלת מהמענה שתקבלו.
      <br />
    </div>
  );
}

export default Messages;
