import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";
import Header from "./header/header";
import Messages from "./message/message";
import AddNames from "./addNames/addNames";
import IconBottom from "./iconBottom/iconBottom";
import Chametz from "./chametz/chametz";

function App() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div className="App" dir="rtl">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<Messages />} />
          <Route path="/add-names" element={<AddNames />} />
          <Route path="/chametz" element={<Chametz />} />
        </Routes>
        <IconBottom />
      </BrowserRouter>
    </div>
  );
}

export default App;
